// @file Settings navigation store
import { trackPageview } from '@@/bits/analytics'
import currentTenant from '@@/bits/current_tenant'
import currentUser from '@@/bits/current_user'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import {
  buildUrlFromPath,
  currentHostname,
  currentUrl,
  doUrlsSharePathPrefix,
  getHostnameFromUrl,
  getPathPrefixFromUrl,
  getSearchParams,
  goBack,
  isCurrentUrlParentOf,
  navigateTo,
} from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import { ContentSafetyEntity, LibraryType } from '@@/enums'
import { useContentSafetySettingsStore } from '@@/pinia/content_safety_settings'
import { useLibraryPlansStore } from '@@/pinia/library_plans'
import { useSettingsBackpackPermissionsStore } from '@@/pinia/settings_backpack_permissions'
import { useSettingsBriefcasePermissionsStore } from '@@/pinia/settings_briefcase_permissions'
import { useSettingsDevelopersStore } from '@@/pinia/settings_developers'
import { useSettingsIntegrationPermissionsStore } from '@@/pinia/settings_integration_permissions'
import { useSettingsLibraryAnalyticsStore } from '@@/pinia/settings_library_analytics_store'
import { useSettingsLibraryInfoStore } from '@@/pinia/settings_library_info'
import { useSettingsLibraryMembersStore } from '@@/pinia/settings_library_members'
import { useSettingsLibrarySecurityStore } from '@@/pinia/settings_library_security'
import { useSettingsNotificationsStore } from '@@/pinia/settings_notifications'
import { useSettingsSchoolPermissionsStore } from '@@/pinia/settings_school_permissions'
import { useSettingsTenantAnalyticsStore } from '@@/pinia/settings_tenant_analytics'
import { useSettingsTenantInfoStore } from '@@/pinia/settings_tenant_info'
import { useSettingsUserDashboardStore } from '@@/pinia/settings_user_dashboard'
import { useSettingsUserInfoStore } from '@@/pinia/settings_user_info'
import type { Library, LibraryId, Tenant, User } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum SettingView {
  // The full size menu is shown when the url is `/dashboard/settings` on mobile and tablet devices.
  FullSizeMenu = '',
  Basic = 'basic',
  Accessibility = 'accessibility',
  Password = 'password',
  Notifications = 'notifications',
  Dashboard = 'dashboard',
  Integrations = 'integrations',
  Developers = 'developers',
  Billing = 'billing',
  Referrals = 'referrals',
  Delete = 'delete',
  OrgBasic = 'org_basic',
  TenantPermissions = 'org_permissions',
  TenantContentSafetySettings = 'org_content_safety',
  OrgBilling = 'org_billing',
  TenantAnalytics = 'org_analytics',
  LibraryInfo = 'library_info',
  LibrarySecurity = 'library_security',
  LibraryMembers = 'library_members',
  LibraryBilling = 'library_billing',
  LibraryDelete = 'library_delete',
  LibraryPermissions = 'library_permissions',
  LibraryContentSafetySettings = 'library_content_safety_settings',
  LibraryAnalytics = 'library_analytics',
}

export const useSettingsNavigationStore = defineStore('settingsNavigation', () => {
  // State
  const currentView = ref<SettingView>(SettingView.FullSizeMenu)
  const currentLibraryId = ref<LibraryId | null>(null)
  const libraries = ref<Library[]>([])
  const user = ref<User>(currentUser)
  const tenant = ref<Tenant>()

  // Getters
  const currentLibrary = computed(() => libraries.value.find((library) => library.id === currentLibraryId.value))
  const isBackpack = computed(() => tenant.value?.type === 'school')
  const isBriefcase = computed(() => tenant.value?.type === 'business')
  const isTeamLibrary = computed(() => currentLibrary.value?.libraryType === LibraryType.Team)
  const isSchoolLibrary = computed(() => currentLibrary.value?.libraryType === LibraryType.School)

  // Actions

  function initializeState(payload: {
    filter: SettingView
    libraryId: LibraryId
    libraries: Library[]
    user: User
    tenant: Tenant
  }): void {
    currentView.value = payload.filter
    currentLibraryId.value = Number(payload.libraryId)
    libraries.value = payload.libraries
    user.value = payload.user
    tenant.value = payload.tenant
  }

  function updatePageTitleAndFocusOnH1(view: SettingView): void {
    let title = ''

    switch (view) {
      case SettingView.Basic:
        title = __('Basic info')
        break
      case SettingView.Accessibility:
        title = __('Accessibility')
        break
      case SettingView.Password:
        title = __('Password')
        break
      case SettingView.Notifications:
        title = __('Notifications')
        break
      case SettingView.Dashboard:
        title = __('Dashboard')
        break
      case SettingView.Integrations:
        title = __('Integrations')
        break
      case SettingView.Developers:
        title = __('Developer')
        break
      case SettingView.Billing:
        title = __('Billing')
        break
      case SettingView.Referrals:
        title = __('Referrals')
        break
      case SettingView.Delete:
        title = __('Delete account')
        break
      case SettingView.OrgBasic:
        title = __('Organization info | %{orgName}', { orgName: currentTenant.name })
        break
      case SettingView.TenantPermissions:
        title = __('Permissions | %{orgName}', { orgName: currentTenant.name })
        break
      case SettingView.TenantContentSafetySettings:
        title = __('Content | %{orgName}', { orgName: currentTenant.name })
        break
      case SettingView.OrgBilling:
        title = __('Billing | %{orgName}', { orgName: currentTenant.name })
        break
      case SettingView.TenantAnalytics:
        title = __('Analytics | %{orgName}', { orgName: currentTenant.name })
        break
      default:
        title = __('Settings')
        break
    }

    if (currentLibrary.value != null) {
      const libraryType = currentLibrary.value.libraryType
      const libraryName = currentLibrary.value.name

      switch (libraryType) {
        case LibraryType.School:
          switch (view) {
            case SettingView.LibraryInfo:
              title = __('School info | %{libraryName}', { libraryName })
              break
            case SettingView.LibrarySecurity:
              title = __('Security | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryMembers:
              title = __('Members | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryBilling:
              title = __('Billing | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryDelete:
              title = __('Delete school | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryPermissions:
              title = __('Permissions | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryContentSafetySettings:
              title = __('Content | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryAnalytics:
              title = __('Analytics | %{libraryName}', { libraryName })
              break
          }
          break
        case LibraryType.Classroom:
          switch (view) {
            case SettingView.LibraryInfo:
              title = __('Classroom info | %{libraryName}', { libraryName })
              break
            case SettingView.LibrarySecurity:
              title = __('Security | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryMembers:
              title = __('Members | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryBilling:
              title = __('Billing | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryDelete:
              title = __('Delete classroom | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryPermissions:
              title = __('Permissions | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryContentSafetySettings:
              title = __('Content | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryAnalytics:
              title = __('Analytics | %{libraryName}', { libraryName })
              break
          }
          break
        case LibraryType.Team:
          switch (view) {
            case SettingView.LibraryInfo:
              title = __('Team info | %{libraryName}', { libraryName })
              break
            case SettingView.LibrarySecurity:
              title = __('Security | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryMembers:
              title = __('Members | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryBilling:
              title = __('Billing | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryDelete:
              title = __('Delete team | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryPermissions:
              title = __('Permissions | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryContentSafetySettings:
              title = __('Content | %{libraryName}', { libraryName })
              break
            case SettingView.LibraryAnalytics:
              title = __('Analytics | %{libraryName}', { libraryName })
              break
          }
          break
      }
    }

    const prevPageTitle = window.document.title
    window.document.title = title

    const focusOnH1Interval = setInterval(() => {
      const h1Element = document.querySelector('h1')
      if (h1Element == null) return

      const h1ElementTextContent = h1Element?.textContent?.trim()
      if (prevPageTitle !== h1ElementTextContent) {
        // if user is still on the menu item button that causes the view to change,
        // we assume they're waiting for the page to load, so we focus on the h1 element once it's loaded
        if (document.activeElement?.textContent?.trim()?.includes(h1ElementTextContent ?? '') ?? false) {
          h1Element.focus()
        }
        clearInterval(focusOnH1Interval)
      }
    }, 100)
  }

  function switchCurrentView(payload: { view: SettingView; libraryId?: LibraryId }): void {
    // We prevent re-initialisation
    // if the view is the same (for non-libraries) and if the library has not changed (for libraries)
    if (currentView.value === payload.view) {
      if (currentLibraryId.value === payload.libraryId || currentLibraryId.value === null) return
    }
    currentView.value = payload.view
    currentLibraryId.value = payload.libraryId ?? null
    if (payload.view !== SettingView.FullSizeMenu) {
      const pageNameInSentenceCase = (payload.view.charAt(0).toUpperCase() + payload.view.slice(1)).replace(/_/g, ' ')
      trackPageview('Settings', pageNameInSentenceCase)
    }

    switch (payload.view) {
      case SettingView.Basic:
      case SettingView.Accessibility:
        void useSettingsUserInfoStore().fetchUserInfo()
        break
      case SettingView.Notifications:
        useSettingsNotificationsStore().initialize()
        break
      case SettingView.Dashboard:
        void useSettingsUserDashboardStore().initialize()
        break
      case SettingView.Developers:
        void useSettingsDevelopersStore().fetchApiToken()
        break
      case SettingView.Billing:
        void getVuexStore()?.dispatch('initializeBillingSettings', { root: true })
        break
      case SettingView.Delete:
        void getVuexStore()?.dispatch('initializeBillingSettings', { root: true })
        break
      case SettingView.OrgBasic:
        useSettingsTenantInfoStore().initialize()
        break
      case SettingView.TenantPermissions:
        if (isBriefcase.value) {
          useSettingsBriefcasePermissionsStore().initialize(tenant.value)
        } else {
          useSettingsBackpackPermissionsStore().initialize(tenant.value)
        }
        // TODO: Remove old fetchTenantPermissions once it is deprecated
        void getVuexStore()?.dispatch('fetchTenantPermissions', { root: true })
        break
      case SettingView.TenantContentSafetySettings:
        if (isBackpack.value) {
          void useContentSafetySettingsStore().initializeState(
            ContentSafetyEntity.Tenant,
            tenant.value?.id.toString() ?? '',
          )
        }
        break
      case SettingView.TenantAnalytics:
        useSettingsTenantAnalyticsStore().initialize(tenant.value)
        break
      case SettingView.Integrations:
        useSettingsIntegrationPermissionsStore().initialize()
        break
      case SettingView.LibraryInfo:
        void useSettingsLibraryInfoStore().fetchLibraryInfo()
        break
      case SettingView.LibrarySecurity:
        void useSettingsLibrarySecurityStore().initialize()
        break
      case SettingView.LibraryMembers:
        void useSettingsLibraryMembersStore().fetchLibraryMemberships()
        if (isTeamLibrary.value && currentLibraryId.value != null) {
          void useLibraryPlansStore().fetchPlans({ libraryId: currentLibraryId.value })
        }
        break
      case SettingView.LibraryContentSafetySettings:
        void useContentSafetySettingsStore().initializeState(
          ContentSafetyEntity.Library,
          currentLibraryId.value?.toString() ?? '',
        )
        break
      case SettingView.LibraryPermissions:
        useSettingsSchoolPermissionsStore().initialize(currentLibrary.value)
        break
      case SettingView.LibraryBilling:
        void getVuexStore()?.dispatch('initializeLibraryBillingSettings', { root: true })
        break
      case SettingView.LibraryAnalytics:
        void useSettingsLibraryAnalyticsStore().initialize(currentLibrary.value)
        break
      case SettingView.LibraryDelete:
        void getVuexStore()?.dispatch('initializeLibraryDeleteSettings', { root: true })
        break
    }

    updatePageTitleAndFocusOnH1(payload.view)
  }

  function switchViewToUrlParams(): void {
    const searchParams = getSearchParams()
    const view = window.location.pathname.replace(/(\/dashboard\/settings|(\/))/g, '') as SettingView
    const libraryId = searchParams.library_id !== undefined ? Number(searchParams.library_id) : undefined

    switchCurrentView({ view, libraryId })
  }

  /**
   * Handles the click event when clicking on the `< Settings` button on the header on mobile and tablet devices.
   */
  function goBackToSettingsMenu(): void {
    const settingsMenuUrl = buildUrlFromPath('dashboard/settings')

    switchCurrentView({ view: SettingView.FullSizeMenu })
    window.history.replaceState({ url: settingsMenuUrl }, '', settingsMenuUrl)
  }

  /**
   * Handles the click event when clicking on the back button on the side nav
   */
  function goBackToReferrerOrDashboard(): void {
    const dashboardUrl = buildUrlFromPath('dashboard')
    const referrer = document.referrer

    // If there's no referrer or the referrer is an external URL (hostnames don't match) -> redirect to the dashboard
    if (referrer === '' || getHostnameFromUrl(referrer) !== currentHostname()) {
      navigateTo(dashboardUrl)
      return
    }

    //  If visit a settings page (basic info page), then go to another settings page (password page) -> redirect to the dashboard
    if (window.history.state?.url === currentUrl()) {
      navigateTo(dashboardUrl)
      return
    }

    // If the current and previous URLs share the same path prefix (e.g /dashboard/settings/billing
    // and /dashboard/settings/delete), redirect to dashboard.
    if (doUrlsSharePathPrefix(referrer, currentUrl({ withQueryString: false }))) {
      navigateTo(dashboardUrl)
      return
    }

    // If the current URL is a parent of the referrer url (For example, the current URL is /dashboard/settings
    // is the parent of /dashboard/settings/basic) then go to the parent of the current URL (/dashboard)
    if (isCurrentUrlParentOf(referrer)) {
      navigateTo(getPathPrefixFromUrl(currentUrl({ withQueryString: false })))
      return
    }

    // If the history length is 1 (means that the current page was
    // open through a link in a new tab), redirect to dashboard
    if (window.history.length === 1) {
      navigateTo(dashboardUrl)
      return
    }

    // Else, go back using browser's history.
    goBack()
  }
  return {
    // State
    currentView,
    currentLibraryId,
    libraries,

    // Getters
    user,
    tenant,
    currentLibrary,
    isBackpack,
    isBriefcase,
    isSchoolLibrary,

    // Actions
    initializeState,
    switchCurrentView,
    switchViewToUrlParams,
    goBackToSettingsMenu,
    goBackToReferrerOrDashboard,
  }
})
